html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
}

.nice-bg {
  background-color: #3949ab;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg stroke='%23F1EDFF' stroke-width='0' stroke-opacity='0.01' %3E%3Ccircle fill='%233949AB' cx='0' cy='0' r='1800'/%3E%3Ccircle fill='%233f4cac' cx='0' cy='0' r='1700'/%3E%3Ccircle fill='%23444fac' cx='0' cy='0' r='1600'/%3E%3Ccircle fill='%234a52ad' cx='0' cy='0' r='1500'/%3E%3Ccircle fill='%234e55ad' cx='0' cy='0' r='1400'/%3E%3Ccircle fill='%235358ae' cx='0' cy='0' r='1300'/%3E%3Ccircle fill='%23585bae' cx='0' cy='0' r='1200'/%3E%3Ccircle fill='%235c5faf' cx='0' cy='0' r='1100'/%3E%3Ccircle fill='%236062af' cx='0' cy='0' r='1000'/%3E%3Ccircle fill='%236465b0' cx='0' cy='0' r='900'/%3E%3Ccircle fill='%236868b0' cx='0' cy='0' r='800'/%3E%3Ccircle fill='%236c6bb0' cx='0' cy='0' r='700'/%3E%3Ccircle fill='%23706fb1' cx='0' cy='0' r='600'/%3E%3Ccircle fill='%237472b1' cx='0' cy='0' r='500'/%3E%3Ccircle fill='%237775b2' cx='0' cy='0' r='400'/%3E%3Ccircle fill='%237b78b2' cx='0' cy='0' r='300'/%3E%3Ccircle fill='%237e7cb3' cx='0' cy='0' r='200'/%3E%3Ccircle fill='%23827FB3' cx='0' cy='0' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

/* SwaggerUI BEGIN */
.swagger-ui {
  color: black;
}

th.response-col_links, td.response-col_links, button.btn.try-out__btn {
  display: none !important;
}

#api_key_value, .auth-btn-wrapper {
  pointer-events: none;
  opacity: 0.5;
  user-select: none;
}

#swagger-json {
  background: rgb(51, 51, 51);
  color: white;
  font-size: 12px;
}

#inline-code {
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 4px;
  border-radius: 4px;
  font-size: 10px;
}

#filetypes-schema,
#filetypes-attachment {
  margin-top: 2px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

#filetype1,
#filetype2,
#filetype3,
#filetype4,
#filetype5,
#filetype6,
#filetype7,
#filetype8 {
  margin-bottom: 6px;
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
}

#header847 {
    background-color: #f0f4f8;
    padding: 14px;
    text-align: left;
}

#title382 {
    font-size: 26px;
    color: #2c3e50;
    margin: 0;
}

#subtitle763 {
    font-size: 18px;
    color: #7f8c8d;
    margin-top: 5px;
}

#description499 {
    padding: 20px;
    background-color: #ecf0f1;
}

#paragraph233 {
    font-size: 16px;
    line-height: 1.6;
    color: #34495e;
}

#features953 {
    padding: 20px;
}

#featurestitle229 {
    font-size: 24px;
    color: #2c3e50;
}

#featureslist432 {
    list-style-type: none;
    padding: 0;
}

#featureitem116, #featureitem832, #featureitem954 {
    font-size: 16px;
    margin: 10px 0;
    color: #2c3e50;
}

#performance469 {
    background-color: #f7f9fa;
    padding: 20px;
}

#paragraph577 {
    font-size: 16px;
    color: #2c3e50;
    line-height: 1.6;
}

/* this hides the URL used in SwaggerUI top section */
.url {
  display: none;
}
/* SwaggerUI END */

@media (max-width: 959px) {
  .usage {
    max-height: calc(100% - 80px);
  }
}

@media (max-width: 959px) {
  .playground {
    max-height: calc(100% - 64px);
  }
}
@media (min-width: 960px) {
  .playground {
    min-height: 100%;
  }
}
@media (max-height: 440px) {
  .playground {
    flex-grow: 1;
    display: block;
  }
}
@media (min-height: 500px) {
  .playground {
    height: 100%;
  }
}
@media (min-height: 441px) {
  .playground {
    display: flex;
  }
}
